#canvas
{
  // Make the footer align to the bottom
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  position: relative;
  z-index: 111;

  margin: 0 auto;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}

#the-body
{
  align-self: flex-start;
  flex-grow: 1;
  flex-shrink: 0;

  position: relative;
  z-index: 111;

  @include set-padding-top-from-header-heights;
  @include set-inner-padding-bottom(3);
  width: 100%;

  .page
  {
	padding-top: 2em;
	padding-bottom: 2em;
	@include container-style;

	.page-title
	{
	  margin-bottom: .5em;
	}
  }
}

button.abtn,
a.abtn,
.abtn a,
.abtn button
{
  @include default-button-style;
}

.checkbox
{
  position: relative;
  width: 100%;

  input[type="checkbox"]
  {
	@include move-off-screen;
  }
  label
  {
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: flex-start;

	position: relative;
	@include set-alternate-text-sizes;

	&:before
	{
	  content: '';
	  display: block;
	  position: relative;
	  z-index: 111;
	  opacity: .8;
	  background: rgba(get-color(1,false),.1) no-repeat 50% 50%;
	  background-size: 80%;
	  border-radius: $_border_radius;
	  margin: 0 .5em 0 0;
	  width: 2rem;
	  height: 2rem;
	}
	.checkmark
	{
	  display: block;
	  visibility: hidden;
	  position: absolute;
	  top: .3rem;
	  left: .3rem;
	  z-index: 222;
	  width: 1.4rem;
	  height: 1.4rem;
	}
  }

  input:checked + label
  {
	.checkmark
	{
	  visibility: visible;
	}
  }
  input:disabled + label
  {
	pointer-events: none;
	cursor: not-allowed;
	opacity: .5;
  }
}