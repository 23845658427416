// Configuration
@import "../../Config";

.match-centre-page
{
  .columns
  {
	.column
	{
	  width: 100%;
	  @include mediaquery(sm)
	  {
		width: calc(33.333333% - .25em);

		.card
		{
		  height: 100%;
		}
	  }

	  @include mediaquery(xs2sm)
	  {
		&:nth-child(2)
		{
		  order: 1;
		}
		&:nth-child(1)
		{
		  order: 2;
		}
		&:nth-child(3)
		{
		  order: 3;
		}
	  }
	}
  }

  .team-dice
  {
	position: relative;
	z-index: 111;

	& + .score
	{
	  padding-top: 0;
	}
  }
  .team-stats
  {
	position: relative;
	z-index: 222;
  }

  .score,
  .game-info
  {
	margin-bottom: 0;
  }

  .save-match-report
  {
	@include default-button-style;
	width: 100%;
  }

}