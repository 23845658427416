// ...
// Configuration
@import "../../Config";

.log
{
  position: relative;
  width: 100%;
  @include set-alternate-text-sizes;

  .log-item
  {
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;

	color: get-color('1faded');
	padding: .15em 0;
	width: 100%;

	.datetime
	{
	  width: 8.8rem;
	  white-space: nowrap;
	}
	.throw
	{
	  width: calc(100% - 8.8rem);
	  white-space: nowrap;
	  overflow: hidden;
	  text-overflow: ellipsis;

	  .value
	  {
		color: get-color(1);
		@include typography-1-medium;
	  }
	}
  }
}