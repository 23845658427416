// ...
// Configuration
@import "../../Config";

.team-page
{
  .card-squad,
  .card-league-position
  {
	.card-title
	{
	  .icon
	  {
		@include mediaquery(landscape)
		{
		  display: none;
		}
		@include mediaquery(sm)
		{
		  display: none;
		}
	  }
	}
  }

  .card-team-info
  {
	.icon-and-values
	{
	  display: flex;
	  flex-wrap: wrap;
	  justify-content: space-between;
	  align-items: flex-start;

	  padding-top: 1em;
	  padding-bottom: 1em;
	  width: 100%;

	  @include mediaquery(sm)
	  {
		flex-wrap: nowrap;
	  }

	  .icon-and-value
	  {
		width: 33.333333%;
		@include mediaquery(sm)
		{
		  width: 30%;
		}

		&.stadium
		{
		  .icon
		  {
			img
			{
			  position: relative;
			  top: .4rem;
			}
		  }
		}
	  }
	}
  }
}