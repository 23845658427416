// Configuration
@import "../../Config";

.team-formations
{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;

  @include mediaquery(sm) {
	flex-wrap: nowrap;
  }

  .team-formation
  {
	@include reset-default-button-style;
	background: get-color('1faded');
	color: get-color(2);
	margin: .25em .1em 0;
	padding: .25em .5em;
	white-space: nowrap;
	@include set-alternate-text-sizes;

	&[data-selected="true"]
	{
	  background: get-color(1);
	}
	&[disabled]
	{
	  pointer-events: none;
	}
  }
}