// Configuration
@import "../../Config";

.team-kit
{
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;

  .kit
  {
	svg
	{
	  width: 100%;
	  max-width: 10rem;
	}
  }
}