// Configuration
@import "../../Config";

.match-report-saver
{
  padding: 3em 0 0;
  @include set-alternate-text-sizes;
  text-align: center;

  .save-match-report
  {
	margin-bottom: 1em;
  }
}