// ...
// Configuration
@import "../../Config";

.kit
{
  position: relative;

  .missing
  {
	position: absolute;
	top: 20%;
	left: 0;
	width: 100%;
	color: get-color('error');
	text-align: center;
	font-size: .8rem;
	line-height: 1em;
  }
}