// Configuration
@import "../../Config";

.columns
{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  @include mediaquery(sm)
  {
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: stretch;
  }

  .column
  {
	position: relative;
	width: 100%;
	@include mediaquery(sm)
	{
	  width: 50%;

	  &:first-child
	  {
		padding-right: .5em;
	  }
	  &:last-child
	  {
		padding-left: .5em;
	  }
	}
  }
}