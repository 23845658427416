// ...
// Configuration
@import "../../Config";

.icon-and-value
{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  width: 100%;

  .icon
  {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: flex-start;
	width: 100%;

	img
	{
	  width: 3rem;
	  height: 3rem;
	  max-width: 100%;

	  @include mediaquery(sm)
	  {
		width: 4rem;
		height: 4rem;
	  }
	}
  }
  .name
  {
	padding: .5em 0 0;
	width: 100%;
	text-align: center;
	line-height: 1.25em;
	white-space: pre-line;

	@include mediaquery(xs2sm)
	{
	  @include set-alternate-text-sizes;
	  line-height: 1.25em;
	}

  }
  .label
  {
	color: get-color('1faded');
	width: 100%;
	text-align: center;
	line-height: 1.25em;
	white-space: pre-line;
	@include set-alternate-text-sizes;
  }
}