// Configuration
@import "../../Config";

.card
{
  background: get-color(2);
  box-shadow: -.1rem 0 .2rem 0 rgba(0,0,0,.05), .1rem 0 .2rem 0 rgba(0,0,0,.05), 0 .1rem .2rem 0 rgba(0,0,0,.05);
  border-radius: $_border_radius;
  margin: 0 0 1em;
  padding: 0 1em 1em;

  @include mediaquery(sm)
  {
	margin: 0 0 1em;
	padding: 0 1.5em 1.5em;
  }

  .card-title
  {
	position: relative;
	left: -1em;
	background: get-color(1);
	//border-bottom: .1rem solid rgba(get-color(1, false), .1);
	color: get-color(2);
	padding: .75em 1em;
	width: calc(100% + 2em);

	@include mediaquery(sm)
	{
	  left: -1.5em;
	  padding: .75em 1.5em;
	  width: calc(100% + 3em);
	}

	.icon
	{
	  position: absolute;
	  top: 50%;
	  right: 1em;
	  width: auto;
	  height: 50%;
	  transform: translate3d(0,-50%,0);
	}

  }
  .no-card-title-spacer
  {
	height: .1em;
  }

  // The misc. components
  & > div,
  & > section
  {
	margin: 0 0 1.5em;

	&:last-child
	{
	  margin-bottom: 0;
	}
  }
}
