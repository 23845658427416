// ...
// Configuration
@import "../../Config";

#the-header
{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: get-color(2);
  box-shadow: 0 .2rem .8rem 0 rgba(get-color(1,false),.08);
  width: 100%;

  transform: translate3d(0,0,0);
  transition: transform .4s ease-out;

  .header-inner
  {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;

	padding-top: 1.5em;
	padding-bottom: 1.5em;
	@include container-style;

	.logo
	{
	  display: block;

	  .copy,
	  .tagline
	  {
		display: block;
		color: get-color(1);
	  }
	  .copy
	  {
		@include typography-1-medium;
		@include text-size(32,1em);
	  }
	  .tagline
	  {
		color: get-color('1faded');
		@include set-alternate-text-sizes;
	  }
	}

	.actions
	{
	  display: flex;
	  flex-wrap: nowrap;
	  justify-content: flex-end;
	  align-items: center;

	  justify-self: flex-end;
	  margin-right: 1.5em;

	  height: 100%;
	  min-width: 11.2rem;

	  @include mediaquery(xs2md)
	  {
		margin-left: auto;
	  }

	  @include mediaquery(md)
	  {
		margin-right: 0;
	  }

	  .action
	  {
		@include reset-default-button-style;

		display: flex;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: center;

		padding: .5em;
		background: get-color('body');
		border-radius: $_border_radius;
		border: .1rem solid rgba(get-color(1, false), .05);
		color: get-color(1);

		img
		{
		  width: 2.4rem;
		  pointer-events: none;
		}
		span
		{
		  display: block;
		  margin: 0 0 0 .5em;
		  @include set-alternate-text-sizes;
		  @include typography-1-light(true);
		  pointer-events: none;
		}
	  }
	}
	#primary-menu-toggler
	{
	  @include reset-default-button-style;

	  width: 2rem;
	  height: 2rem;

	  &:before,
	  &:after
	  {
		content: '';
		display: block;
		background: get-color(1);
		margin: .5rem 0;
		width: 100%;
		height: .3rem;
	  }

	  @include mediaquery(sm)
	  {
		display: none !important;
	  }
	}
  }

  &.js-unsticky
  {
	transform: translate3d(0,-120%,0);
  }
}

@include mediaquery(xs2sm)
{
  .js-primary-menu-revealed
  {
	overflow: hidden;

	.logo,
	.actions,
	#primary-menu-toggler
	{
	  position: relative;
	  z-index: 222;
	}

	.menu-primary
	{
	  display: block;
	  position: fixed;
	  top: 0;
	  left: 0;
	  z-index: 111;
	  background: get-color(2);
	  width: 100%;
	  height: 100vh;
	}
  }
}