// Configuration
@import "../../Config";

#the-footer
{
  position: relative;
  z-index: 111;
  background: get-color(3);
  width: 100%;

  .footer-inner
  {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;

	padding-top: 2em;
	padding-bottom: 2em;
	@include container-style;
	@include set-alternate-text-sizes;
  }
}