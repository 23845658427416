// Configuration
@import "../../Config";

.score
{
  padding-top: .2em;
  width: 100%;

  text-align: center;
  @include typography-1-bold;
  line-height: 1em;
  font-size: 8rem;

}