// ...
// Configuration
@import "../../Config";

.squad
{
  position: relative;
  left: -1.5em;
  width: calc(100% + 3em);

  .players-header,
  .player
  {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
	padding: .5em 1.5em;
  }
  .players-header
  {
	@include typography-1-medium;
	padding: 0 1.5em 1em;
  }
  .players-body
  {
	.player
	{
	  @include mediaquery(hover)
	  {
		background: rgba(get-color(1,false),.05);
	  }
	}
  }

  .shirt
  {
	flex-shrink: 0;
	flex-grow: 0;
	text-align: center;
	width: 3rem;
  }
  .age
  {
	flex-shrink: 0;
	flex-grow: 0;
	text-align: center;
	width: 5rem;
  }
  .positions
  {
	flex-shrink: 0;
	flex-grow: 0;
	width: 12rem;
	text-align: right;
  }
  .technique
  {
	flex-shrink: 0;
	flex-grow: 0;
	width: 5rem;
	text-align: right;
  }
  .nationality
  {
	flex-shrink: 0;
	flex-grow: 0;

	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;

	width: 5rem;

	img
	{
	  width: 3rem;
	  max-width: 100%;
	  height: 3rem;
	}
  }
  .name
  {
	width: 100%;
  }

  // Show/hide columns for different screensizes
  .age,
  .nationality
  {
	display: none;
	@include mediaquery(landscape)
	{
	  display: flex;
	}
	@include mediaquery(md)
	{
	  display: flex;
	}
  }

}