// ...
// Configuration
@import "../../Config";

$width_score__xs: 5.4rem;
$width_score__sm: 6.4rem;

.fixture
{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  padding: 0 0 1.5em;

  .round
  {
	padding: 0 0 .25em;
	color: get-color('1faded');
	width: 100%;
	text-align: center;
	@include set-alternate-text-sizes;
  }

  .teams
  {
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;

	width: 100%;

	.team
	{
	  flex-grow: 0;
	  flex-shrink: 0;

	  width: calc(50% - #{$width_score__xs} / 2);
	  text-align: left;
	  white-space: nowrap;
	  overflow: hidden;
	  text-overflow: ellipsis;

	  @include mediaquery(sm)
	  {
		width: calc(50% - #{$width_score__sm} / 2);
	  }

	  &:first-child
	  {
		text-align: right;
		padding-right: .75em;
	  }
	  &:last-child
	  {
		padding-left: .75em;
	  }

	  &.team-1
	  {
		@include typography-1-medium;
	  }

	  @include mediaquery(xs2sm)
	  {
		@include set-alternate-text-sizes;
	  }

	}

	.score
	{
	  flex-grow: 0;
	  flex-shrink: 0;

	  display: flex;
	  flex-wrap: nowrap;
	  justify-content: center;
	  align-items: center;

	  background: get-color(3);
	  border-radius: $_border_radius;
	  border: .1rem solid rgba(get-color(1, false), .1);
	  color: get-color(1);
	  padding: .5em 0;
	  width: $width_score__xs;
	  @include typography-1-medium;
	  line-height: 1em;
	  font-size: 1.4rem;

	  @include mediaquery(sm)
	  {
		width: $width_score__sm;
		font-size: 1.6rem;
	  }

	  .seperator
	  {
		display: block;
		background: rgba(get-color(1, false), .1);
		width: .1rem;
		height: 2rem;
	  }
	  span:not(.seperator)
	  {
		display: block;
		margin-bottom: -.1rem;
		width: calc(50% - .1rem);
		text-align: center;
	  }
	}
  }
}