// Configuration
@import "../../Config";

$max_width_player: 4.2rem;

.player-positions
{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 333;
  width: 100%;
  background: rgba(0,0,0,.05);
  overflow: hidden;

  @include aspect-ratio(720,1080); // aspect ratio of the pitch

  &[data-loading="true"]
  {
	.player
	{
	  opacity: 0;
	}
  }
  &[data-is-dragging="true"]
  {
	.player
	{
	  transition: none !important;
	}
  }

  .player
  {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 1;
	@include aspect-ratio(1,1);
	width: $max_width_player;
	overflow: visible;
	transform: translate3d(-50%,-50%,0);
	cursor: pointer;
	transition: all .2s ease-out;

	&[data-draggable="false"]
	{
	  pointer-events: none;
	  cursor: auto;
	}
	&[data-reverse="true"].goalkeeper
	{
	  // we can reposition the GK when reversed because the GK can't be moved...
	  transform: translate3d(-50%,-65%,0);
	}
	&.goalkeeper
	{
	  .position
	  {
		// always hide the position indicator
		display: none;
	  }
	}

	&:after,
	.team-kit-wrapper
	{
	  display: block;
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: $max_width_player;
	  height: $max_width_player;
	  overflow: hidden;
	  pointer-events: none;
	  -webkit-user-select: none;
	  -moz-user-select: none;
	  user-select: none;
	}

	.team-kit-wrapper
	{
	  z-index: 111;
	  background: get-color(2);
	  border-radius: 50%;
	  border: .4rem solid transparent;

	  &[data-all-white="true"]
	  {
		background: get-color('tacticBoardPlayer');
	  }

	  .team-kit
	  {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 222;

		width: 100%;
		height: auto;

		.shorts
		{
		  display: none;
		}
	  }
	}

	.player-info
	{
	  position: relative;
	  top: 0;
	  left: 0;
	  z-index: 333;
	  padding: .5rem 0 0;
	  width: 100%;
	  max-height: 100%;
	  pointer-events: none;
	  -webkit-user-select: none;
	  -moz-user-select: none;
	  user-select: none;

	  @include text-size(10,1.4em);

	  .name
	  {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: center;

		position: absolute;
		bottom: -1.25em;
		left: -1.75em;
		color: get-color(2);

		width: calc(100% + 3.5em);
		height: 1.8rem;

		.display-name
		{
		  padding: 0 .35em 0 .4em;
		  width: 100%;
		  letter-spacing: .02em;
		  white-space: nowrap;
		  text-align: center;
		  text-shadow: 0 0 .2rem rgba(get-color(1, false), 1);
		  text-overflow: ellipsis;
		  overflow: hidden;

		  .number
		  {
			@include typography-1-medium;
		  }
		}
	  }
	}
	.position
	{
	  position: absolute;
	  left: 0;
	  bottom: -1.1em;
	  color: get-color(2);
	  width: 100%;
	  letter-spacing: .02em;
	  white-space: nowrap;
	  text-align: center;
	  text-shadow: 0 0 .2rem rgba(get-color(1, false), 1);
	  font-size: .8rem;
	}
  }
}