// ...
// Configuration
@import "../../Config";

.tables-page
{
  .card-table
  {
	.card-title
	{
	  .icon
	  {
		@include mediaquery(landscape)
		{
		  display: none;
		}
		@include mediaquery(sm)
		{
		  display: none;
		}
	  }
	}
  }

  .table
  {
	position: relative;
	left: -1em;
	width: calc(100% + 2em);

	@include mediaquery(sm)
	{
	  left: -1.5em;
	  width: calc(100% + 3em);
	}

	.table-header,
	.table-team
	{
	  padding-left: .5em;
	  padding-right: .5em;
	  @include mediaquery(sm)
	  {
		padding-left: 1.5em;
		padding-right: 1.5em;
	  }
	}

	.table-team
	{
	  &:nth-child(7)
	  {
		border-bottom: .4rem solid get-color(1);
		//margin-bottom: .4rem;
	  }
	  &:nth-child(8)
	  {
		padding-top: .9em;
	  }
	  &:last-child
	  {
		border: none;
	  }
	}
  }
}