@use 'sass:math';

// ...
// ASPECT RATIOS
@mixin aspect-ratio($w, $h, $before: true) {

  overflow: hidden;

  @if $before {
	&:before {
	  content: '';
	  display: block;
	  padding-top: percentage(math.div($h,$w));
	  //padding-top: percentage($h / $w);
	  overflow: hidden;
	}
  }
  @else {
	&:after {
	  content: '';
	  display: block;
	  padding-top: percentage(math.div($h,$w));
	  //padding-top: percentage($h / $w);
	  overflow: hidden;
	}
  }
}

@mixin no-aspect-ratio($before: true) {

  @if $before {
	&:before {
	  display: inherit;
	  content: inherit;
	  padding-top: inherit;
	}
  }
  @else {
	&:after {
	  display: inherit;
	  content: inherit;
	  padding-top: inherit;
	}
  }
}

@mixin set-16-9-iframe() {
  position: relative;
  @include aspect-ratio(16, 9);

  iframe
  {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	vertical-align: top;
	width: 100%;
	height: 100%;
  }
}

// ...
// FULLSCREEN MANIPULATION
@mixin move-off-screen() {
  position: absolute;
  left: -99999rem;
}
@mixin make-fullscreen() {
  position: relative;
  left: 50%; // may have to be set to 0 because if flex-direction: column;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: none;
  width: 100vw;
}
@mixin disable-fullscreen() {
  position: relative;
  left: auto;
  right: auto;
  margin-left: auto;
  margin-right: auto;
  max-width: none;
  width: auto;
}
@mixin set-max-height-from-viewport($vh: 100) {
  // Set a max height
  max-height: calc(#{$vh}vh - #{$_height_header_xs});
  @if($_height_header_sm != $_height_header_xs)	{
	@include mediaquery(sm) {
	  max-height: calc(#{$vh}vh - #{$_height_header_sm});
	}
  }
  @if($_height_header_md != $_height_header_sm)	{
	@include mediaquery(md) {
	  max-height: calc(#{$vh}vh - #{$_height_header_md});
	}
  }
  @if($_height_header_lg != $_height_header_md)	{
	@include mediaquery(lg) {
	  max-height: calc(#{$vh}vh - #{$_height_header_lg});
	}
  }
}
@mixin set-height-from-viewport($vh: 100) {
  // Set a height
  height: calc(#{$vh}vh - #{$_height_header_xs});
  @if($_height_header_sm != $_height_header_xs)	{
	@include mediaquery(sm) {
	  height: calc(#{$vh}vh - #{$_height_header_sm});
	}
  }
  @if($_height_header_md != $_height_header_sm)	{
	@include mediaquery(md) {
	  height: calc(#{$vh}vh - #{$_height_header_md});
	}
  }
  @if($_height_header_lg != $_height_header_md)	{
	@include mediaquery(lg) {
	  height: calc(#{$vh}vh - #{$_height_header_lg});
	}
  }
}

// ...
// MAX WIDTHS
@mixin set-max-width-container() {
  max-width: $_max_width_container_xs;

  @if ($_max_width_container_sm != $_max_width_container_xs) {
	@include mediaquery(sm) {
	  max-width: $_max_width_container_sm;
	}
  }
  @if ($_max_width_container_md != $_max_width_container_sm) {
	@include mediaquery(md) {
	  max-width: $_max_width_container_md;
	}
  }
  @if ($_max_width_container_lg != $_max_width_container_md) {
	@include mediaquery(lg) {
	  max-width: $_max_width_container_lg;
	}
  }
}
@mixin set-max-width-content() {
  max-width: $_max_width_content_xs;

  @if ($_max_width_content_sm != $_max_width_content_xs) {
	@include mediaquery(sm) {
	  max-width: $_max_width_content_sm;
	}
  }
  @if ($_max_width_content_md != $_max_width_content_sm) {
	@include mediaquery(md) {
	  max-width: $_max_width_content_md;
	}
  }
  @if ($_max_width_content_lg != $_max_width_content_md) {
	@include mediaquery(lg) {
	  max-width: $_max_width_content_lg;
	}
  }
}
@mixin set-max-width-container-exclude-padding() {
  max-width: calc(#{$_max_width_container_xs} - #{$_inner_padding_xs * 2});

  @include mediaquery(sm) {
	max-width: calc(#{$_max_width_container_sm} - #{$_inner_padding_sm});
  }
  @include mediaquery(md) {
	max-width: calc(#{$_max_width_container_md} - #{$_inner_padding_md});
  }
  @include mediaquery(lg) {
	max-width: calc(#{$_max_width_container_lg} - #{$_inner_padding_lg});
  }
}

// ...
// CONTAINER
@mixin container-style() {
  position: relative;
  margin: 0 auto;

  @include set-inner-padding-right(1);
  @include set-inner-padding-left(1);

  @include set-max-width-container;
  width: 100%;
}

// ...
// PADDINGS
@mixin set-inner-padding-sides($multiplier_top: 1, $multiplier_right: 1, $multiplier_bottom: 1, $multiplier_left: 1) {

  padding: ($_inner_padding_xs * $multiplier_top) ($_inner_padding_xs * $multiplier_right) ($_inner_padding_xs * $multiplier_bottom) ($_inner_padding_xs * $multiplier_left);

  @if ($_inner_padding_sm != $_inner_padding_xs) {
	@include mediaquery(sm) {
	  padding: ($_inner_padding_sm * $multiplier_top) ($_inner_padding_sm * $multiplier_right) ($_inner_padding_sm * $multiplier_bottom) ($_inner_padding_sm * $multiplier_left);
	}
  }
  @if ($_inner_padding_md != $_inner_padding_sm) {
	@include mediaquery(md) {
	  padding: ($_inner_padding_md * $multiplier_top) ($_inner_padding_md * $multiplier_right) ($_inner_padding_md * $multiplier_bottom) ($_inner_padding_md * $multiplier_left);
	}
  }
  @if ($_inner_padding_lg != $_inner_padding_md) {
	@include mediaquery(lg) {
	  padding: ($_inner_padding_lg * $multiplier_top) ($_inner_padding_lg * $multiplier_right) ($_inner_padding_lg * $multiplier_bottom) ($_inner_padding_lg * $multiplier_left);
	}
  }
}
@mixin set-inner-padding-top($multiplier: 1) {

  padding-top: ($_inner_padding_xs * $multiplier);

  @if ($_inner_padding_sm != $_inner_padding_xs) {
	@include mediaquery(sm) {
	  padding-top: ($_inner_padding_sm * $multiplier);
	}
  }
  @if ($_inner_padding_md != $_inner_padding_sm) {
	@include mediaquery(md) {
	  padding-top: ($_inner_padding_md * $multiplier);
	}
  }
  @if ($_inner_padding_lg != $_inner_padding_md) {
	@include mediaquery(lg) {
	  padding-top: ($_inner_padding_lg * $multiplier);
	}
  }
}
@mixin set-inner-padding-right($multiplier: 1) {

  padding-right: ($_inner_padding_xs * $multiplier);

  @if ($_inner_padding_sm != $_inner_padding_xs) {
	@include mediaquery(sm) {
	  padding-right: ($_inner_padding_sm * $multiplier);
	}
  }
  @if ($_inner_padding_md != $_inner_padding_sm) {
	@include mediaquery(md) {
	  padding-right: ($_inner_padding_md * $multiplier);
	}
  }
  @if ($_inner_padding_lg != $_inner_padding_md) {
	@include mediaquery(lg) {
	  padding-right: ($_inner_padding_lg * $multiplier);
	}
  }
}
@mixin set-inner-padding-bottom($multiplier: 1) {

  padding-bottom: ($_inner_padding_xs * $multiplier);

  @if ($_inner_padding_sm != $_inner_padding_xs) {
	@include mediaquery(sm) {
	  padding-bottom: ($_inner_padding_sm * $multiplier);
	}
  }
  @if ($_inner_padding_md != $_inner_padding_sm) {
	@include mediaquery(md) {
	  padding-bottom: ($_inner_padding_md * $multiplier);
	}
  }
  @if ($_inner_padding_lg != $_inner_padding_md) {
	@include mediaquery(lg) {
	  padding-bottom: ($_inner_padding_lg * $multiplier);
	}
  }
}
@mixin set-inner-padding-left($multiplier: 1) {

  padding-left: ($_inner_padding_xs * $multiplier);

  @if ($_inner_padding_sm != $_inner_padding_xs) {
	@include mediaquery(sm) {
	  padding-left: ($_inner_padding_sm * $multiplier);
	}
  }
  @if ($_inner_padding_md != $_inner_padding_sm) {
	@include mediaquery(md) {
	  padding-left: ($_inner_padding_md * $multiplier);
	}
  }
  @if ($_inner_padding_lg != $_inner_padding_md) {
	@include mediaquery(lg) {
	  padding-left: ($_inner_padding_lg * $multiplier);
	}
  }
}

// ...
// MARGINS
@mixin set-inner-margin-sides($multiplier_top: 1, $multiplier_right: 1, $multiplier_bottom: 1, $multiplier_left: 1) {

  margin: ($_inner_padding_xs * $multiplier_top) ($_inner_padding_xs * $multiplier_right) ($_inner_padding_xs * $multiplier_bottom) ($_inner_padding_xs * $multiplier_left);

  @if ($_inner_padding_sm != $_inner_padding_xs) {
	@include mediaquery(sm) {
	  margin: ($_inner_padding_sm * $multiplier_top) ($_inner_padding_sm * $multiplier_right) ($_inner_padding_sm * $multiplier_bottom) ($_inner_padding_sm * $multiplier_left);
	}
  }
  @if ($_inner_padding_md != $_inner_padding_sm) {
	@include mediaquery(md) {
	  margin: ($_inner_padding_md * $multiplier_top) ($_inner_padding_md * $multiplier_right) ($_inner_padding_md * $multiplier_bottom) ($_inner_padding_md * $multiplier_left);
	}
  }
  @if ($_inner_padding_lg != $_inner_padding_md) {
	@include mediaquery(lg) {
	  margin: ($_inner_padding_lg * $multiplier_top) ($_inner_padding_lg * $multiplier_right) ($_inner_padding_lg * $multiplier_bottom) ($_inner_padding_lg * $multiplier_left);
	}
  }
}
@mixin set-inner-margin-top($multiplier: 1) {

  margin-top: ($_inner_padding_xs * $multiplier);

  @if ($_inner_padding_sm != $_inner_padding_xs) {
	@include mediaquery(sm) {
	  margin-top: ($_inner_padding_sm * $multiplier);
	}
  }
  @if ($_inner_padding_md != $_inner_padding_sm) {
	@include mediaquery(md) {
	  margin-top: ($_inner_padding_md * $multiplier);
	}
  }
  @if ($_inner_padding_lg != $_inner_padding_md) {
	@include mediaquery(lg) {
	  margin-top: ($_inner_padding_lg * $multiplier);
	}
  }
}
@mixin set-inner-margin-right($multiplier: 1) {

  margin-right: ($_inner_padding_xs * $multiplier);

  @if ($_inner_padding_sm != $_inner_padding_xs) {
	@include mediaquery(sm) {
	  margin-right: ($_inner_padding_sm * $multiplier);
	}
  }
  @if ($_inner_padding_md != $_inner_padding_sm) {
	@include mediaquery(md) {
	  margin-right: ($_inner_padding_md * $multiplier);
	}
  }
  @if ($_inner_padding_lg != $_inner_padding_md) {
	@include mediaquery(lg) {
	  margin-right: ($_inner_padding_lg * $multiplier);
	}
  }
}
@mixin set-inner-margin-bottom($multiplier: 1) {

  margin-bottom: ($_inner_padding_xs * $multiplier);

  @if ($_inner_padding_sm != $_inner_padding_xs) {
	@include mediaquery(sm) {
	  margin-bottom: ($_inner_padding_sm * $multiplier);
	}
  }
  @if ($_inner_padding_md != $_inner_padding_sm) {
	@include mediaquery(md) {
	  margin-bottom: ($_inner_padding_md * $multiplier);
	}
  }
  @if ($_inner_padding_lg != $_inner_padding_md) {
	@include mediaquery(lg) {
	  margin-bottom: ($_inner_padding_lg * $multiplier);
	}
  }
}
@mixin set-inner-margin-left($multiplier: 1) {

  margin-left: ($_inner_padding_xs * $multiplier);

  @if ($_inner_padding_sm != $_inner_padding_xs) {
	@include mediaquery(sm) {
	  margin-left: ($_inner_padding_sm * $multiplier);
	}
  }
  @if ($_inner_padding_md != $_inner_padding_sm) {
	@include mediaquery(md) {
	  margin-left: ($_inner_padding_md * $multiplier);
	}
  }
  @if ($_inner_padding_lg != $_inner_padding_md) {
	@include mediaquery(lg) {
	  margin-left: ($_inner_padding_lg * $multiplier);
	}
  }
}

// ...
// HEADER HEIGHTS
@mixin set-header-heights() {

  height: $_height_header_xs;

  @if ($_height_header_sm != $_height_header_xs) {
	@include mediaquery(sm) {
	  height: $_height_header_sm;
	}
  }
  @if ($_height_header_md != $_height_header_sm) {
	@include mediaquery(md) {
	  height: $_height_header_md;
	}
  }
  @if ($_height_header_lg != $_height_header_md) {
	@include mediaquery(lg) {
	  height: $_height_header_lg;
	}
  }
}
@mixin set-padding-top-from-header-heights() {

  padding-top: $_height_header_xs;

  @if ($_height_header_sm != $_height_header_xs) {
	@include mediaquery(sm) {
	  padding-top: $_height_header_sm;
	}
  }
  @if ($_height_header_md != $_height_header_sm) {
	@include mediaquery(md) {
	  padding-top: $_height_header_md;
	}
  }
  @if ($_height_header_lg != $_height_header_md) {
	@include mediaquery(lg) {
	  padding-top: $_height_header_lg;
	}
  }
}


//
// BLOCK Margins...
@mixin block-margin-top($multiplier:1, $important:false) {
  @if($important) {
	margin-top: $_line_height_body_xs * $multiplier !important;
  }
  @else {
	margin-top: $_line_height_body_xs * $multiplier;
  }

  @if($_line_height_alternate_sm != $_line_height_alternate_xs) {
	@if($important) {
	  margin-top: $_line_height_body_sm * $multiplier !important;
	}
	@else {
	  margin-top: $_line_height_body_sm * $multiplier;
	}
  }

  @if($_line_height_alternate_md != $_line_height_alternate_sm) {
	@if($important) {
	  margin-top: $_line_height_body_md * $multiplier !important;
	}
	@else {
	  margin-top: $_line_height_body_md * $multiplier;
	}
  }

  @if($_line_height_alternate_lg != $_line_height_alternate_md) {
	@if($important) {
	  margin-top: $_line_height_body_lg * $multiplier !important;
	}
	@else {
	  margin-top: $_line_height_body_lg * $multiplier;
	}
  }
}
@mixin block-margin-right($multiplier:1, $important:false) {
  @if($important) {
	margin-right: $_line_height_body_xs * $multiplier !important;
  }
  @else {
	margin-right: $_line_height_body_xs * $multiplier;
  }

  @if($_line_height_alternate_sm != $_line_height_alternate_xs) {
	@if($important) {
	  margin-right: $_line_height_body_sm * $multiplier !important;
	}
	@else {
	  margin-right: $_line_height_body_sm * $multiplier;
	}
  }

  @if($_line_height_alternate_md != $_line_height_alternate_sm) {
	@if($important) {
	  margin-right: $_line_height_body_md * $multiplier !important;
	}
	@else {
	  margin-right: $_line_height_body_md * $multiplier;
	}
  }

  @if($_line_height_alternate_lg != $_line_height_alternate_md) {
	@if($important) {
	  margin-right: $_line_height_body_lg * $multiplier !important;
	}
	@else {
	  margin-right: $_line_height_body_lg * $multiplier;
	}
  }
}
@mixin block-margin-bottom($multiplier:1, $important:false) {
  @if($important) {
	margin-bottom: $_line_height_body_xs * $multiplier !important;
  }
  @else {
	margin-bottom: $_line_height_body_xs * $multiplier;
  }

  @if($_line_height_alternate_sm != $_line_height_alternate_xs) {
	@if($important) {
	  margin-bottom: $_line_height_body_sm * $multiplier !important;
	}
	@else {
	  margin-bottom: $_line_height_body_sm * $multiplier;
	}
  }

  @if($_line_height_alternate_md != $_line_height_alternate_sm) {
	@if($important) {
	  margin-bottom: $_line_height_body_md * $multiplier !important;
	}
	@else {
	  margin-bottom: $_line_height_body_md * $multiplier;
	}
  }

  @if($_line_height_alternate_lg != $_line_height_alternate_md) {
	@if($important) {
	  margin-bottom: $_line_height_body_lg * $multiplier !important;
	}
	@else {
	  margin-bottom: $_line_height_body_lg * $multiplier;
	}
  }
}
@mixin block-margin-left($multiplier:1, $important:false) {
  @if($important) {
	margin-left: $_line_height_body_xs * $multiplier !important;
  }
  @else {
	margin-left: $_line_height_body_xs * $multiplier;
  }

  @if($_line_height_alternate_sm != $_line_height_alternate_xs) {
	@if($important) {
	  margin-left: $_line_height_body_sm * $multiplier !important;
	}
	@else {
	  margin-left: $_line_height_body_sm * $multiplier;
	}
  }

  @if($_line_height_alternate_md != $_line_height_alternate_sm) {
	@if($important) {
	  margin-left: $_line_height_body_md * $multiplier !important;
	}
	@else {
	  margin-left: $_line_height_body_md * $multiplier;
	}
  }

  @if($_line_height_alternate_lg != $_line_height_alternate_md) {
	@if($important) {
	  margin-left: $_line_height_body_lg * $multiplier !important;
	}
	@else {
	  margin-left: $_line_height_body_lg * $multiplier;
	}
  }
}

//
// BLOCK Paddings...
@mixin block-padding-top($multiplier:1, $important:false) {
  @if($important) {
	padding-top: $_line_height_body_xs * $multiplier !important;
  }
  @else {
	padding-top: $_line_height_body_xs * $multiplier;
  }

  @if($_line_height_alternate_sm != $_line_height_alternate_xs) {
	@if($important) {
	  padding-top: $_line_height_body_sm * $multiplier !important;
	}
	@else {
	  padding-top: $_line_height_body_sm * $multiplier;
	}
  }

  @if($_line_height_alternate_md != $_line_height_alternate_sm) {
	@if($important) {
	  padding-top: $_line_height_body_md * $multiplier !important;
	}
	@else {
	  padding-top: $_line_height_body_md * $multiplier;
	}
  }

  @if($_line_height_alternate_lg != $_line_height_alternate_md) {
	@if($important) {
	  padding-top: $_line_height_body_lg * $multiplier !important;
	}
	@else {
	  padding-top: $_line_height_body_lg * $multiplier;
	}
  }
}
@mixin block-padding-right($multiplier:1, $important:false) {
  @if($important) {
	padding-right: $_line_height_body_xs * $multiplier !important;
  }
  @else {
	padding-right: $_line_height_body_xs * $multiplier;
  }

  @if($_line_height_alternate_sm != $_line_height_alternate_xs) {
	@if($important) {
	  padding-right: $_line_height_body_sm * $multiplier !important;
	}
	@else {
	  padding-right: $_line_height_body_sm * $multiplier;
	}
  }

  @if($_line_height_alternate_md != $_line_height_alternate_sm) {
	@if($important) {
	  padding-right: $_line_height_body_md * $multiplier !important;
	}
	@else {
	  padding-right: $_line_height_body_md * $multiplier;
	}
  }

  @if($_line_height_alternate_lg != $_line_height_alternate_md) {
	@if($important) {
	  padding-right: $_line_height_body_lg * $multiplier !important;
	}
	@else {
	  padding-right: $_line_height_body_lg * $multiplier;
	}
  }
}
@mixin block-padding-bottom($multiplier:1, $important:false) {
  @if($important) {
	padding-bottom: $_line_height_body_xs * $multiplier !important;
  }
  @else {
	padding-bottom: $_line_height_body_xs * $multiplier;
  }

  @if($_line_height_alternate_sm != $_line_height_alternate_xs) {
	@if($important) {
	  padding-bottom: $_line_height_body_sm * $multiplier !important;
	}
	@else {
	  padding-bottom: $_line_height_body_sm * $multiplier;
	}
  }

  @if($_line_height_alternate_md != $_line_height_alternate_sm) {
	@if($important) {
	  padding-bottom: $_line_height_body_md * $multiplier !important;
	}
	@else {
	  padding-bottom: $_line_height_body_md * $multiplier;
	}
  }

  @if($_line_height_alternate_lg != $_line_height_alternate_md) {
	@if($important) {
	  padding-bottom: $_line_height_body_lg * $multiplier !important;
	}
	@else {
	  padding-bottom: $_line_height_body_lg * $multiplier;
	}
  }
}
@mixin block-padding-left($multiplier:1, $important:false) {
  @if($important) {
	padding-left: $_line_height_body_xs * $multiplier !important;
  }
  @else {
	padding-left: $_line_height_body_xs * $multiplier;
  }

  @if($_line_height_alternate_sm != $_line_height_alternate_xs) {
	@if($important) {
	  padding-left: $_line_height_body_sm * $multiplier !important;
	}
	@else {
	  padding-left: $_line_height_body_sm * $multiplier;
	}
  }

  @if($_line_height_alternate_md != $_line_height_alternate_sm) {
	@if($important) {
	  padding-left: $_line_height_body_md * $multiplier !important;
	}
	@else {
	  padding-left: $_line_height_body_md * $multiplier;
	}
  }

  @if($_line_height_alternate_lg != $_line_height_alternate_md) {
	@if($important) {
	  padding-left: $_line_height_body_lg * $multiplier !important;
	}
	@else {
	  padding-left: $_line_height_body_lg * $multiplier;
	}
  }
}