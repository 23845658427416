// ...
// Configuration
@import "../../Config";

.form
{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  .label
  {
	color: get-color('1faded');
	padding-right: .5em;
  }

  .result
  {
	display: flex;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;

	border-radius: $_border_radius;
	margin: -.1rem .5em 0 0;
	width: 1.8rem;
	height: 1.8rem;

	span
	{
	  @include set-alternate-text-sizes;
	}

	&.result-w
	{
	  background: get-color('won');
	  color: get-color(2);
	}
	&.result-d
	{
	  background: get-color('drawn');
	}
	&.result-l
	{
	  background: get-color('lost');
	  color: get-color(2);
	}
	&.no-result
	{
	  background: transparent;
	  color: get-color('1faded');
	}
  }
}