// Configuration
@import "../../Config";

.team-info
{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  width: 100%;

  .icon-and-values
  {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;

	width: 100%;

	.icon-and-value
	{
	  width: 50%;

	  .icon
	  {
		img
		{
		  max-width: 2.6rem;
		}
	  }
	}
  }
}