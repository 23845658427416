// Configuration
@import "../../Config";

.team-summary
{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  padding: 0 0 2.5em;

  .team-name
  {
	width: 100%;
  }

  .current-position
  {
	color: get-color('1faded');
  }
}