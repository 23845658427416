// Configuration
@import "../../../Config";

.archive-fixture
{
  .match-report-link
  {
	display: inline-flex;
	color: get-color(1);
	margin: 0 auto 1em;
	padding: .5em 0;
	@include typography-1-medium;
	transition: color .2s ease-out;

	@include mediaquery(hover)
	{
	  color: get-color('1faded');
	}
  }
}