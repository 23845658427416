// ...
// Configuration
@import "../../Config";

.teams-page
{
  .columns
  {
	.column
	{
	  width: 100%;
	  @include mediaquery(sm)
	  {
		width: calc(33.333333% - .25em);

		.card
		{
		  height: 100%;
		}
	  }
	}
  }

  .teams
  {
	ul
	{
	  li
	  {
		margin: 0 0 -1em;
	  }
	}
  }

  .team
  {
	display: inline-flex;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;

	color: get-color(1);

	.kit
	{
	  margin-right: 1em;
	  width: 5rem;

	  .shorts
	  {
		display: none;
	  }
	}
	.name
	{
	  display: block;
	  position: relative;
	  top: -1em;
	}
  }
}