:root
{
  @include set-root-colors;
}
*,
*:before,
*:after
{
  box-sizing: border-box;
}
html
{
  min-height: 100%;
  font-size: 62.5%;
}
body
{
  //@include text-rendering; // Disable font-smoothing?!
  @include typography-1-light(true);
  @include set-default-text-sizes;

  @include set-background-color(body);
  @include set-color(1);
}
h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4,
h5,.h5,
h6,.h6
{
  @include typography-1-light;
}
h1,.h1
{
  padding-bottom: .25em;
  @include typography-1-medium;
  @include text-size(30, 1em);
  @include mediaquery(sm)
  {
	@include text-size(44, 1em);
  }
}
h2,.h2
{
  @include text-size(24, 1em);
  @include mediaquery(sm)
  {
	@include text-size(34, 1em);
  }
}
h3,.h3
{
  @include text-size(20, 1.25em);
  @include mediaquery(sm)
  {
	@include text-size(24, 1.25em);
  }
}
h4,.h4
{
  @include text-size(20, 1.25em);
}
h5,.h5
{
  @include text-size(18, 1.25em);
}
h6,.h6
{
  @include set-default-text-sizes;
}
a
{
  color: get-color(1);
  text-decoration: none;
}
strong
{
  @include typography-1-medium;
}
i,
em
{
  font-style: italic;
}
p
{
  @include block-padding-bottom;
}