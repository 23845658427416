@mixin set-root-colors() {
  @each $name, $color in $_colors {
	--color-#{$name}: #{$color};
  }
}
@function get-color($color, $useRoot: $_useRootColors) {
  @if($useRoot) {
	@return var(--color-#{$color});
  }
  @return map-get($_colors, $color);
}


@mixin set-color($color, $important: false, $useRoot: $_useRootColors) {
  @if($important) {
	color: get-color($color, $useRoot) !important;
  }
  @else {
	color: get-color($color, $useRoot);
  }
}

@mixin set-background-color($color, $important: false, $useRoot: $_useRootColors) {
  @if($important) {
	background-color: get-color($color, $useRoot) !important;
  }
  @else {
	background-color: get-color($color, $useRoot);
  }
}

@mixin set-border-color($color, $important: false, $useRoot: $_useRootColors) {
  @if($important) {
	border-color: get-color($color, $useRoot) !important;
  }
  @else {
	border-color: get-color($color, $useRoot);
  }
}