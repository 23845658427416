@mixin placeholder($color, $color_invalid) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
  &.js-invalid {
    color: $color_invalid;
  }
  &.js-invalid::-webkit-input-placeholder {
    color: $color_invalid;
  }
  &.js-invalid::-moz-placeholder {
    color: $color_invalid;
  }
  &.js-invalid:-ms-input-placeholder {
    color: $color_invalid;
  }
}

@mixin js-error() {

  backface-visibility: hidden;
  animation: app-shake-js-error .75s cubic-bezier(.36,.07,.19,.97) both;

  // Setup key frame animation
  @keyframes app-shake-js-error {
	0% { transform: translate3d(.5rem, .1rem, 0); }
	10% { transform: translate3d(-.1rem, -.4rem, 0); }
	20% { transform: translate3d(-.3rem, 0rem, 0); }
	30% { transform: translate3d(0, .3rem, 0); }
	40% { transform: translate3d(.1rem, -.1rem, 0); }
	50% { transform: translate3d(-.1rem, .2rem, 0); }
	60% { transform: translate3d(-.5rem, .1rem, 0); }
	70% { transform: translate3d(.2rem, .1rem, 0); }
	80% { transform: translate3d(-.2rem, -.1rem, 0); }
	90% { transform: translate3d(.2rem, .2rem, 0); }
	100% { transform: translate3d(.3rem, -.2rem, 0); }
  }
}

@mixin no-appearance() {
  -webkit-appearance: none;
  -moz-appearance: none;
}

@mixin user-select-none() {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin default-button-style() {
  @include reset-default-button-style;

  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  position: relative;
  background: get-color(4);
  border-radius: $_border_radius;
  color: get-color(2);
  padding: 1em 2em;

  text-transform: uppercase;
  @include typography-1-medium;
  @include text-size(16,1em);

  transition: background-color .2s ease-out, color .2s ease-out;

  &:before
  {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	background: rgba(0,0,0,.2);
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: opacity .2s ease-out;
  }

  @include mediaquery(hover)
  {
	&:before
	{
	  opacity: 1;
	}
  }

  span
  {
	position: relative;
	z-index: 222;
	pointer-events: none;
  }
}

@mixin reset-default-button-style() {
  background: none;
  border: none;
  border-radius: 0;
  outline: none;

  @include no-appearance;

  -webkit-tap-highlight-color: transparent !important;
  -webkit-backface-visibility:  hidden;
  transform: translate3d(0,0,0);
  cursor: pointer;
  margin: 0;
  padding: 0;
}