// ...
// Configuration
@import "../../Config";

.menu-primary
{
  @include mediaquery(xs2sm)
  {
	display: none;
	padding-top: 10em;
  }

  .menu
  {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;

	@include mediaquery(sm)
	{
	  flex-wrap: nowrap;
	}

	.menu-item
	{
	  margin: 1em 0;
	  width: 100%;
	  line-height: 1em;
	  text-align: center;

	  @include mediaquery(xs2sm)
	  {
		font-size: 2rem;
	  }

	  @include mediaquery(sm)
	  {
		margin: 0 .5em;
		width: auto;
		text-align: left;
	  }
	  @include mediaquery(md)
	  {
		margin: 0 1em;
		width: auto;
		text-align: left;
	  }

	  a
	  {
		position: relative;
		color: get-color(1);

		&:after
		{
		  content: '';
		  position: absolute;
		  left: 0;
		  bottom: -.2rem;
		  background: get-color(1);
		  width: 100%;
		  height: .1rem;
		  transition: transform .2s ease-out;
		  transform: translate3d(0,0,0) scaleX(0);
		}

		@include mediaquery(hover)
		{
		  color: get-color(1);
		  &:after
		  {
			transform: translate3d(0,0,0) scaleX(1);
		  }
		}

		&.js-active
		{
		  &:after
		  {
			transform: translate3d(0,0,0) scaleX(1);
		  }
		}
	  }
	}
  }
}