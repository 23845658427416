// ...
// Configuration
@import "../../Config";

.roll-btn
{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;

  padding: 0 0 1em;
  width: 100%;

  button
  {
	@include default-button-style;
	@include set-alternate-text-sizes;
	@include typography-1-bold;

	opacity: .8;
	margin: 0 0 .75em;
	width: 100%;
	white-space: nowrap;

	&:disabled
	{
	  pointer-events: none;
	  cursor: not-allowed;
	  opacity: .5;
	}
  }
}