// Configuration
@import "../../Config";

.kit-designer-page
{
  .dev-kits
  {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: center;

	width: 100%;

	.dev-kit
	{
	  margin: 0 0 1em;
	  width: 25%;
	  max-width: 160px;

	  .dev-kit-inner
	  {
		.getting-dressed
		{
		  position: relative;
		  @include aspect-ratio(168, 267.89);
		  .label
		  {
			position: absolute;
			top: 25%;
			left: 0;
			width: 100%;
			text-align: center;
			@include set-alternate-text-sizes;
		  }
		}

		.design
		{
		  padding: 0 0 1em;
		  width: 100%;
		  text-align: center;
		  @include typography-1-medium;
		}
	  }
	}
  }

  .color-pickers
  {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: flex-start;

	margin-bottom: 3em;

	width: 100%;

	.labels
	{
	  display: flex;
	  flex-wrap: wrap;
	  justify-content: center;
	  align-items: flex-start;

	  width: 100%;

	  @include mediaquery(sm)
	  {
		justify-content: space-between;
		flex-wrap: nowrap;
	  }

	  .label
	  {
		padding: 0 0 .5em;
		width: calc(33.333333% - 1em) !important;
		@include typography-1-medium;
		@include set-alternate-text-sizes;
		text-align: center;

		@include mediaquery(sm)
		{
		  width: calc(20% - 1em) !important;
		}
	  }
	}

	.pickers
	{
	  display: flex;
	  flex-wrap: wrap;
	  justify-content: center;
	  align-items: flex-start;

	  width: 100%;

	  @include mediaquery(sm)
	  {
		justify-content: space-between;
		flex-wrap: nowrap;
	  }

	  .chrome-picker
	  {
		box-sizing: border-box !important;
		box-shadow: none !important;
		border: 1px solid get-color(1);
		border-radius: 0 !important;
		margin: 0 !important;
		width: calc(33.333333% - 1em) !important;

		@include mediaquery(sm)
		{
		  width: calc(20% - 1em) !important;
		}
	  }
	}
  }
}