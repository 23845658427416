// ...
// Configuration
@import "../../Config";

.mc-players
{
  margin-top: 1.5em;
  .players-header
  {
	padding-top: .25em;
	padding-bottom: .25em;
  }
  .players-body
  {
	.player
	{
	  padding-top: .25em;
	  padding-bottom: .25em;

	  &:nth-child(1n+12)
	  {
		color: get-color('1faded');
	  }
	}
  }

  .positions
  {
	width: 4rem;
  }
}