// ...
// Configuration
@import "../../Config";

.team-dice
{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;

  margin: -2em 0 0 !important;
  padding: 0 0 3em;
  width: 100%;

  .dice-wrapper
  {
	display: flex;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: flex-start;

	position: relative;
	margin: 0 0 -2em;
	width: 60%;
	min-height: 20rem;

	.loading
	{
	  position: absolute;
	  top: 50%;
	  left: 0;
	  color: get-color('1faded');
	  width: 100%;
	  text-align: center;
	  @include set-alternate-text-sizes;
	}
  }
}