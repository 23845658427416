// Configuration
@import "../../Config";

.hidden-player-positions
{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 666;
  background: rgba(0,0,0,.55);
  width: 100%;

  @include aspect-ratio(720,1080); // aspect ratio of the pitch

  .hidden-player-positions-inner
  {
	position: absolute;
	top: 50%;
	left: 0;
	padding: 0 1em;
	width: 100%;
	text-align: center;
	transform: translate3d(0,-50%,0);

	a
	{
	  color: get-color(2);
	  @include typography-1-bold;
	  @include text-size(16, 1.5em);
	}
  }
}