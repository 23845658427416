// ...
// Configuration
@import "../../Config";

.league-position
{
  &[data-loading="true"]
  {
	.table-team
	{
	  .position
	  {
		visibility: hidden;
	  }
	}
  }

  .won,
  .drawn,
  .lost,
  .for,
  .against
  {
	display: none !important;
  }
  .played,
  .gd,
  .points
  {
	@include mediaquery(sm)
	{
	  margin: 0 !important;
	}
  }
  .team
  {
	@include mediaquery(sm2md)
	{
	  max-width: 52% !important;
	}
  }

  .table-team
  {
	&[data-human="true"]
	{
	  .position,
	  .team
	  {
		@include typography-1-light;
	  }
	}
	@include mediaquery(hover)
	{
	  background: none;
	}
	.slug
	{
	  pointer-events: none;
	}

  }

  .form-guide
  {
	margin-left: 1em;
	.form
	{
	  max-width: 4.2rem;
	}
  }
}