// Configuration
@import "../../Config";

.team-stats
{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  padding: 1.5em 0 0;
  width: 100%;

  .stats
  {
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;

	width: 100%;
	max-width: 36rem;

	@include mediaquery(sm2md) {
	  justify-content: center;
	  flex-wrap: wrap;
	}

	.stat
	{
	  display: flex;
	  flex-wrap: wrap;
	  justify-content: center;
	  align-items: center;

	  background: rgba(get-color(1,false),.05);
	  color: get-color(1);
	  margin: 0 0;
	  padding: .5em 0;
	  width: 4.3rem;
	  overflow: hidden;

	  @include set-alternate-text-sizes;

	  @include mediaquery(sm2md) {
		width: 25%;
	  }

	  .label,
	  .value
	  {
		display: flex;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: flex-start;

		width: 100%;
	  }
	  .value
	  {
		@include typography-1-medium;
		@include text-size(16, 1em);
		@include mediaquery(md) {
		  @include text-size(20, 1em);
		}
	  }

	  &.stat-team
	  {
		justify-self: flex-end;
		margin-left: auto;
	  }
	  &.stat-team,
	  &.stat-form,
	  &.stat-tac
	  {
		background: rgba(get-color(1,false),.1);
	  }
	}
  }
  .checkbox
  {
	max-width: 36rem;
  }
}