// ...
// Configuration
@import "../../Config";

.kits
{
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;

  width: 100%;

  .kit-wrapper
  {
	width: 30%;

	.what
	{
	  color: get-color('1faded');
	  width: 100%;
	  text-align: center;
	  @include set-alternate-text-sizes;
	}
  }
}