// ...
// Configuration
@import "../../Config";

.fixtures
{
  &:not(:last-child)
  {
	margin-bottom: 0;
  }
}