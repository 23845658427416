@use 'sass:math';

@mixin typography-1-light($includeFamilyAndWeight: false) {
  font-weight: 300; // ( Light )
  @if($includeFamilyAndWeight) {
	font-family: $_font_family_1_light;
	font-style: normal;
  }
}
@mixin typography-1-medium($includeFamilyAndWeight: false) {
  font-weight: 500; // ( Medium )
  @if($includeFamilyAndWeight) {
	font-family: $_font_family_1_medium;
	font-style: normal;
  }
}
@mixin typography-1-bold($includeFamilyAndWeight: false) {
  font-weight: 700; // ( Bold )
  @if($includeFamilyAndWeight) {
	font-family: $_font_family_1_bold;
	font-style: normal;
  }
}

@mixin font-face($name, $file, $weight: normal, $style: normal, $display: swap){
  @font-face {
	font-family: "#{$name}";
	src:url('#{$_path_fonts}#{$file}.woff2') format('woff2'),
		url('#{$_path_fonts}#{$file}.woff') format('woff');
	font-weight: $weight;
	font-style: $style;
	font-display: $display;
  }
}

@mixin text-rendering() {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

@mixin text-size($fs, $lh, $important: false) {
  @include line-height($lh, $important);
  @include font-size($fs, $important);
}

@mixin line-height($lh, $important: false) {
  @if index('rem' 'em' 'px' '%', unit($lh)) != null {
	@if($important) {
	  line-height: $lh !important;
	}
	@else {
	  line-height: $lh;
	}
  }
  @else { // convert to rem
	@if($important) {
	  line-height: math.div($lh, 10) + rem !important;
	  //line-height: $lh/10 + rem !important;
	}
	@else {
	  line-height: math.div($lh, 10) + rem;
	  //line-height: $lh/10 + rem;
	}
  }
}

@mixin font-size($fs, $important: false) {
  @if index('rem' 'em' 'px' '%', unit($fs)) != null {
	@if($important) {
	  font-size: $fs !important;
	}
	@else {
	  font-size: $fs;
	}
  }
  @else { // convert to rem
	@if($important) {
	  font-size: math.div($fs, 10) + rem !important;
	  //font-size: $fs/10 + rem !important;
	}
	@else {
	  font-size: math.div($fs, 10) + rem;
	  //font-size: $fs/10 + rem;
	}
  }
}

@mixin set-default-text-sizes($important: false) {

  // Include the mobile sizes
  @include text-size($_font_size_body_xs, $_line_height_body_xs, $important);

  // Include the other breakpoints
  @if ($_font_size_body_sm != $_font_size_body_xs or $_line_height_body_sm != $_line_height_body_xs) {
	@include mediaquery(sm) {
	  @include text-size($_font_size_body_sm, $_line_height_body_sm, $important);
	}
  }
  @if ($_font_size_body_md != $_font_size_body_sm or $_line_height_body_md != $_line_height_body_sm) {
	@include mediaquery(md) {
	  @include text-size($_font_size_body_md, $_line_height_body_md, $important);
	}
  }
  @if ($_font_size_body_lg != $_font_size_body_md or $_line_height_body_lg != $_line_height_body_md) {
	@include mediaquery(lg) {
	  @include text-size($_font_size_body_lg, $_line_height_body_lg, $important);
	}
  }
}
@mixin set-alternate-text-sizes($important: false) {

  // Include the mobile sizes
  @include text-size($_font_size_alternate_xs, $_line_height_alternate_xs, $important);

  // Include the other breakpoints
  @if ($_font_size_alternate_sm != $_font_size_alternate_xs or $_line_height_alternate_sm != $_line_height_alternate_xs) {
	@include mediaquery(sm) {
	  @include text-size($_font_size_alternate_sm, $_line_height_alternate_sm, $important);
	}
  }
  @if ($_font_size_alternate_md != $_font_size_alternate_sm or $_line_height_alternate_md != $_line_height_alternate_sm) {
	@include mediaquery(md) {
	  @include text-size($_font_size_alternate_md, $_line_height_alternate_md, $important);
	}
  }
  @if ($_font_size_alternate_lg != $_font_size_alternate_md or $_line_height_alternate_lg != $_line_height_alternate_md) {
	@include mediaquery(lg) {
	  @include text-size($_font_size_alternate_lg, $_line_height_alternate_lg, $important);
	}
  }
}