// ...
// Version
$_css_version: 071;

// ...
// Paths
$_path_images: 'images/';
$_path_fonts: 'fonts/';

// ...
// Media Query Breakpoints
@mixin mediaquery($size) {
  @if $size == xs {
	@media (max-width: 767px) { @content; }
  }
  @else if $size == sm {
	@media (min-width: 768px) { @content; }
  }
  @else if $size == md {
	@media (min-width: 1024px) { @content; }
  }
  @else if $size == lg {
	@media (min-width: 1280px) { @content; }
  }

  @else if $size == xs320 {
	@media (max-width: 374px) { @content; }
  }
  @else if $size == xs2sm {
	@media (max-width: 767px) { @content; }
  }
  @else if $size == xs2md {
	@media (max-width: 1023px) { @content; }
  }
  @else if $size == sm2md {
	@media (min-width: 768px) and (max-width: 1023px) { @content; }
  }
  @else if $size == md2lg {
	@media (min-width: 1024px) and (max-width: 1279px) { @content; }
  }

  @else if $size == hover {
	@media (hover: hover) { &:hover { @content; } }
  }

  @else if $size == portrait {
	@media only screen and (orientation: portrait) { @content; }
  }
  @else if $size == landscape {
	@media only screen and (orientation: landscape) { @content; }
  }
}

// ...
// Default Text Sizes
$_font_size_body_xs		: 1.4rem;
$_line_height_body_xs	: 1.5em;

$_font_size_body_sm		: 1.4rem;
$_line_height_body_sm	: 1.5em;

$_font_size_body_md		: 1.4rem;
$_line_height_body_md	: 1.5em;

$_font_size_body_lg		: 1.4rem;
$_line_height_body_lg	: 1.5em;

// ...
// Alternate Text sizes
$_font_size_alternate_xs	: 1.2rem;
$_line_height_alternate_xs	: 1.5em;

$_font_size_alternate_sm	: 1.2rem;
$_line_height_alternate_sm	: 1.5em;

$_font_size_alternate_md	: 1.2rem;
$_line_height_alternate_md	: 1.5em;

$_font_size_alternate_lg	: 1.2rem;
$_line_height_alternate_lg	: 1.5em;

// ...
// Fonts
$_font_family_1_light: 'Poppins', serif;
$_font_family_1_medium: 'Poppins', serif;
$_font_family_1_bold: 'Poppins', serif;

// ...
// Colors
$_colors: (
	0: #000,
	1: #343434,
	1faded: rgba(#343434, .5),
	2: #FFF,
	3: #FFE66D,
	3hover: #FFE66D,
	4: #CE2D4F,

	// Results
	won: #70C1B3,
	lost: #F25F5C,
	drawn: #FFE66D,

	body: #F9F9F9,
	tacticBoardPlayer: rgba(#343434, .5),

	error: #CE2D4F,
);

$_useRootColors: true; // Globally boolean to define whether to user #000 or var(--color-0). May be individually overridden in get-color()

// ...
// Misc. properties
$_grid_size: 1.5rem;

$_inner_padding_xs: 1em;
$_inner_padding_sm: 2rem;
$_inner_padding_md: 3rem;
$_inner_padding_lg: 3rem;

$_max_width_container_xs: calc(123rem + #{$_inner_padding_xs} * 2); // Fixed width :-/ //100%; //calc(100% - #{$_inner_padding_xs});
$_max_width_container_sm: calc(123rem + #{$_inner_padding_sm} * 2); // Fixed width :-/
$_max_width_container_md: calc(123rem - #{$_inner_padding_md} * 2);
$_max_width_container_lg: calc(123rem - #{$_inner_padding_lg} * 2);

$_max_width_content_xs: $_max_width_container_xs;
$_max_width_content_sm: $_max_width_container_sm; //74rem;
$_max_width_content_md: $_max_width_container_md; //74rem;
$_max_width_content_lg: $_max_width_container_lg; //74rem;

$_border_radius: 0;

// Useful if fixed header
$_height_header_xs: 9.2rem;
$_height_header_sm: 9.2rem;
$_height_header_md: 9.2rem;
$_height_header_lg: 9.2rem;

// ...
// Mixins
@import "Mixins/Form";
@import "Mixins/Layout";
@import "Mixins/Typography";
@import "Mixins/Color";