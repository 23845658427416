// Configuration
@import "../../../Config";

.archive-match-centre-page
{
  .how-it-ended
  {
	color: get-color('1faded');
	margin-bottom: 1em;
	padding: 1em 0;
	text-align: center;
  }
  .tactic-board
  {
	.team-formations,
	.checkbox
	{
	  display: none !important;
	}
	.player-positions
	{
	  pointer-events: none !important;
	}
  }
}