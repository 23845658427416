// Configuration
@import "../../Config";

.tactic-board
{
  position: relative;
  left: -1em;
  padding: 0 1em;
  width: calc(100% + 2em);

  background: get-color(2);
  color: get-color(2);
  margin: 0 0 1.5em;

  @include mediaquery(sm)
  {
	left: -1.5em;
	padding: 0 1.5em;
	width: calc(100% + 3em);
  }

  .pitch
  {
	position: relative;
	left: -1em;
	z-index: 111;
	opacity: 1;
	width: calc(100% + 2em);

	@include mediaquery(sm)
	{
	  left: -1.5em;
	  width: calc(100% + 3em);
	}

	img
	{
	  position: relative;
	  z-index: 111;
	  width: 100%;
	}
  }

  .team-formations
  {
	position: relative;
	z-index: 777;
  }

  .checkbox
  {
	color: get-color(1);
	margin: .5em 0 0;


	input[disabled]
	{
	  color: pink;
	}
  }

}