// Configuration
@import "../../Config";

.game-info
{
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;

  position: relative;
  left: -1em;
  padding: 2em 0 1em;
  width: calc(100% + 2em);

  @include mediaquery(sm) {
	left: -1.5em;
	width: calc(100% + 3em);
  }

  .icon-and-value
  {
	padding: 0 .75em;
	width: 33.333333%;

	&.weather
	{
	  .name
	  {
		text-transform: capitalize;
	  }
	}
	.icon
	{
	  img
	  {
		max-width: 2.6rem;
	  }
	}

	.name,
	.label
	{
	  @include set-alternate-text-sizes;
	  line-height: 1.3em;
	}
  }
}