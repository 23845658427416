// ...
// Configuration
@import "../../Config";

.table
{
  width: 100%;

  .table-header
  {
	div
	{
	  @include typography-1-medium;
	}
  }
  .table-header,
  .table-team
  {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;

	position: relative;
	border-bottom: .1rem solid rgba(get-color(1,false),.05);

	padding: .75em 0;
	width: 100%;

	&[data-human="true"],
	&[data-highlight="true"]
	{
	  .position,
	  .team
	  {
		@include typography-1-bold;
	  }
	}
	&[data-human="true"][data-highlight="false"]
	{
	  .position,
	  .team
	  {
		@include typography-1-light;
	  }
	}

	.slug
	{
	  display: block;
	  position: absolute;
	  top: 0;
	  left: 0;
	  z-index: 222;
	  background: transparent;
	  width: 100%;
	  height: 100%;
	}

	.team
	{
	  flex-grow: 1;
	  white-space: nowrap;
	  max-width: 60%;
	  overflow: hidden;
	  text-overflow: ellipsis;

	  @include mediaquery(landscape)
	  {
		max-width: 31%;
	  }
	  @include mediaquery(sm)
	  {
		max-width: 32%;
	  }
	  @include mediaquery(md)
	  {
		max-width: none;
	  }
	}

	.position,
	.played,
	.won,
	.drawn,
	.lost,
	.for,
	.against,
	.gd,
	.points
	{
	  margin-left: auto;
	  text-align: center;
	  width: 4rem;
	}
	.position
	{
	  margin-left: 0;
	  width: 3rem;
	}
	.played,
	.lost,
	.gd,
	.points
	{
	  @include mediaquery(sm)
	  {
		margin-right: 1em;
	  }
	}

	.form-guide
	{
	  min-width: 7rem;
	  .form
	  {
		width: 7rem;
	  }

	  .result
	  {
		margin: 0 .4rem 0 0;
		width: 1rem;
		height: 1rem;
		span
		{
		  display: none;
		}
	  }
	}

	// Show/hide columns for different screensizes
	.won,
	.drawn,
	.lost,
	.for,
	.against,
	.gd,
	.form-guide
	{
	  display: none;
	  @include mediaquery(landscape)
	  {
		display: block;
	  }
	  @include mediaquery(sm)
	  {
		display: block;
	  }
	}
	.form-guide
	{
	  @include mediaquery(sm)
	  {
		display: none;
	  }
	  @include mediaquery(md)
	  {
		display: block;
	  }
	}

  }
  .table-header
  {
	padding-top: 0;
  }
  .table-team
  {
	@include mediaquery(hover)
	{
	  background: rgba(get-color(1,false),.05);
	}
  }
}