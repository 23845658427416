// ...
// Configuration
@import "../../Config";

.menu-secondary
{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;

  position: absolute;
  top: 75%;
  right: 0;
  z-index: 999;
  padding: 2em;

  @include set-inner-margin-right;

  background: get-color(1);
  color: get-color(2);
  //box-shadow: 0 .2rem .8rem 0 rgba(get-color(1,false),.4);
}