// Configuration
@import "../../Config";

.todo-page
{
  li
  {
	position: relative;

	margin: .5em 0;
	padding: 0 0 0 1em;
	&:before
	{
	  display: block;
	  content: '';
	  position: absolute;
	  top: .5em;
	  left: 0;
	  background: get-color(1);
	  border-radius: 50%;
	  width: .6rem;
	  height: .6rem;
	}
  }
}