// Configuration
@import "../../Config";

.events
{
  width: 100%;
  .timeline
  {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column-reverse;

	position: relative;
	width: 100%;

	// axis
	&:before
	{
	  content: '';
	  position: absolute;
	  top: 0;
	  left: 50%;
	  z-index: 111;
	  background: get-color('1faded');
	  pointer-events: none;
	  height: 100%;
	  width: .1rem;

	}
	.event
	{
	  display: flex;
	  flex-wrap: wrap;
	  justify-content: center;
	  align-items: center;

	  position: relative;
	  z-index: 222;
	  margin: 1em 0;
	  width: 100%;
	  @include set-alternate-text-sizes;
	  line-height: 1.25em;

	  &.no-event
	  {
		.info
		{
		  background: get-color(3);
		  padding: 2em 0;
		}
		.icon
		{
		  display: none;
		}
	  }

	  .details
	  {
		order: 1;
		padding: 0 .5em 0 0;
		width: calc(50% - 2.5rem);
		text-align: right;

		.label
		{
		  @include typography-1-medium;
		}

	  }
	  .icon
	  {
		order: 2;

		display: flex;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;

		background: get-color(2);
		width: 5rem;
		height: 7rem;
		text-align: center;

		img
		{
		  width: 100%;
		  height: auto;
		}

	  }
	  .minute
	  {
		order: 3;
		padding: 0 0 0 .5em;
		width: calc(50% - 2.5rem);
	  }
	  .info
	  {
		order: 4;
		background: get-color(2);
		padding: 0 0 1em;
		width: 100%;
		text-align: center;
	  }

	  &.away-team
	  {
		.minute
		{
		  order: 1;
		  padding: 0 .5em 0 0;
		  text-align: right;
		}
		.details
		{
		  order: 3;
		  padding: 0 0 0 .5em;
		  text-align: left;
		}
	  }

	  &.milestone
	  {
		&:last-child
		{
		  margin-top: 0;
		}

		.icon
		{
		  position: relative;
		  z-index: 1;
		  width: 2.6rem;
		}
		.info
		{
		  position: relative;
		  z-index: 222;
		  margin-top: -1.5em;
		  padding-bottom: 1.5em;
		}
	  }
	}
  }
}