// ...
// Configuration
@import "../../Config";

.die
{
  position: relative;
  width: 50%;
  height: 20rem;

  transform: translate3d(0,0,0) scale3d(.5,.5,.5);

  // Rolling
  transition: transform 2s cubic-bezier(0.34, 1.26, 0.64, 1);

  &[data-face]
  {
	opacity: 1;
  }
  .die-inner
  {
	transition: transform 2s cubic-bezier(0.34, 1.26, 0.64, 1);
	transform-origin: 50% 50%;
  }
  &[data-default-animation="true"]
  {
	.die-inner
	{
	  //transform: rotateX(-360deg) rotateY(-360deg) rotateZ(0deg) !important;
	  transform: rotateX(-720deg) rotateY(-720deg) rotateZ(0deg) !important;
	}
  }

  // Hidden
  &[data-face="??"]
  {
	.die-inner
	{
	  .face
	  {
		&:before
		{
		  content: '??' !important;
		}
	  }
	}
  }
  &[data-face="?"]
  {
	.die-inner
	{
	  .face
	  {
		&:before
		{
		  content: '?' !important;
		}
	  }
	}
  }

  .die-inner
  {
	position: absolute;
	width: 100%;
	height: 100%;
	transform-style: preserve-3d;
  }
  .face
  {
	position: absolute;
	left: 50%;
	top: 0;
	opacity: .85;
	//filter: brightness(90%);
	border-left: 5.2rem solid transparent;
	border-right: 5.2rem solid transparent;
	border-bottom: 10rem solid #000;
	margin: 0 -5.2rem;
	width: 0;
	height: 0;
	transform-style: preserve-3d;
	backface-visibility: hidden;

	&:before
	{
	  content: attr(data-face);
	  position: absolute;
	  top: 2.5rem;
	  left: -5.2rem;
	  color: #FFF;
	  width: 10.4rem;
	  height: 10rem;
	  /*
	  text-shadow: -.1rem -.1rem 0 rgba(get-color(0, false), .1),
	  				.1rem -.1rem 0 rgba(get-color(0, false), .1),
	  				-.1rem .1rem 0 rgba(get-color(0, false), .1),
	  				.1rem .1rem 0 rgba(get-color(0, false), .1);*/
	  font-size: 5.4rem;
	  @include typography-1-bold;
	  text-align: center;
	  line-height: 2em;
	}

	&:after
	{
	  content: "";
	  position: absolute;
	  bottom: -12.4rem;
	  left: -5.2rem;
	  border-left: 5.2rem solid transparent;
	  border-right: 5.2rem solid transparent;
	  border-top: 2.4rem solid #000;
	  width: 0;
	  height: 0;
	}
  }

  // Tens
  &[data-power="tens"]
  {
	.die-inner
	{
	  .face
	  {
		&:before
		{
		  line-height: 2.75em;
		  font-size: 4rem;
		}
	  }
	}
  }

  &[data-default-animation="true"]
  {
	.die-inner
	{
	  transform: rotateX(-360deg) rotateY(-360deg) rotateZ(0deg) !important;
	}
  }

  // Face 0
  &[data-face="0"]
  {
	.die-inner
	{
	  transform: rotateX(-45deg) rotateY(0deg) rotateZ(0deg) !important;
	}
	&[data-default-animation="true"]
	{
	  .die-inner
	  {
		transform: rotateX(-405deg) rotateY(-360deg) rotateZ(0deg) !important;
	  }
	}
  }
  .die-inner
  {
	.face-0
	{
	  transform: rotateY(0deg) translateZ(34px) translateY(6.96px) rotateX(45deg);
	}
  }

  // Face 1
  &[data-face="1"]
  {
	.die-inner
	{
	  transform: rotateX(-225deg) rotateY(-72deg) rotateZ(360deg) !important;
	}
	&[data-default-animation="true"]
	{
	  .die-inner
	  {
		transform: rotateX(-585deg) rotateY(288deg) rotateZ(-360deg) !important;
	  }
	}
  }
  .die-inner
  {
	.face-1
	{
	  top: 100px;
	  transform: rotateY(72deg) translateZ(-34px) translateY(-6.96px) rotateZ(180deg) rotateY(180deg) rotateX(45deg);
	}
  }

  // Face 2
  &[data-face="2"]
  {
	.die-inner
	{
	  transform: rotateX(-45deg) rotateY(72deg) rotateZ(720deg) !important;
	}
	&[data-default-animation="true"]
	{
	  .die-inner
	  {
		transform: rotateX(-405deg) rotateY(432deg) rotateZ(360deg) !important;
	  }
	}
  }
  .die-inner
  {
	.face-2
	{
	  transform: rotateY(-72deg) translateZ(34px) translateY(6.96px) rotateX(45deg);
	}
  }

  // Face 3
  &[data-face="3"]
  {
	.die-inner
	{
	  transform: rotateX(-225deg) rotateY(-144deg) rotateZ(360deg) !important;
	}
	&[data-default-animation="true"]
	{
	  .die-inner
	  {
		transform: rotateX(-585deg) rotateY(216deg) rotateZ(-360deg) !important;
	  }
	}
  }
  .die-inner
  {
	.face-3
	{
	  top: 100px;
	  transform: rotateY(144deg) translateZ(-34px) translateY(-6.96px) rotateZ(180deg) rotateY(180deg) rotateX(45deg);
	}
  }

  // Face 4
  &[data-face="4"]
  {
	.die-inner
	{
	  transform: rotateX(-45deg) rotateY(144deg) rotateZ(0deg) !important;
	}
	&[data-default-animation="true"]
	{
	  .die-inner
	  {
		transform: rotateX(-405deg) rotateY(504deg) rotateZ(720deg) !important;
	  }
	}
  }
  .die-inner
  {
	.face-4
	{
	  transform: rotateY(-144deg) translateZ(34px) translateY(6.96px) rotateX(45deg);
	}
  }

  // Face 5
  &[data-face="5"]
  {
	.die-inner
	{
	  transform: rotateX(-225deg) rotateY(-216deg) rotateZ(360deg) !important;
	}
	&[data-default-animation="true"]
	{
	  .die-inner
	  {
		transform: rotateX(-585deg) rotateY(144deg) rotateZ(-360deg) !important;
	  }
	}
  }
  .die-inner
  {
	.face-5
	{
	  top: 100px;
	  transform: rotateY(216deg) translateZ(-34px) translateY(-6.96px) rotateZ(180deg) rotateY(180deg) rotateX(45deg);
	}
  }

  // Face 6
  &[data-face="6"]
  {
	.die-inner
	{
	  transform: rotateX(-45deg) rotateY(216deg) rotateZ(720deg) !important;
	}
	&[data-default-animation="true"]
	{
	  .die-inner
	  {
		transform: rotateX(-405deg) rotateY(576deg) rotateZ(0deg) !important;
	  }
	}
  }
  .die-inner
  {
	.face-6
	{
	  transform: rotateY(-216deg) translateZ(34px) translateY(6.96px) rotateX(45deg);
	}

  }

  // Face 7
  &[data-face="7"]
  {
	.die-inner
	{
	  transform: rotateX(-225deg) rotateY(-288deg) rotateZ(0deg) !important;
	}
	&[data-default-animation="true"]
	{
	  .die-inner
	  {
		transform: rotateX(-585deg) rotateY(72deg) rotateZ(720deg) !important;
	  }
	}
  }
  .die-inner
  {
	.face-7
	{
	  top: 100px;
	  transform: rotateY(288deg) translateZ(-34px) translateY(-6.96px) rotateZ(180deg) rotateY(180deg) rotateX(45deg);
	}
  }

  // Face 8
  &[data-face="8"]
  {
	.die-inner
	{
	  transform: rotateX(-45deg) rotateY(288deg) rotateZ(720deg) !important;
	}
	&[data-default-animation="true"]
	{
	  .die-inner
	  {
		transform: rotateX(-405deg) rotateY(648deg) rotateZ(0deg) !important;
	  }
	}
  }
  .die-inner
  {
	.face-8
	{
	  transform: rotateY(-288deg) translateZ(34px) translateY(6.96px) rotateX(45deg);
	}
  }

  // Face 9
  &[data-face="9"]
  {
	.die-inner
	{
	  transform: rotateX(-225deg) rotateY(-360deg) rotateZ(360deg) !important;
	}
	&[data-default-animation="true"]
	{
	  .die-inner
	  {
		transform: rotateX(-585deg) rotateY(-720deg) rotateZ(-360deg) !important;
	  }
	}
  }
  .die-inner
  {
	.face-9
	{
	  top: 100px;
	  transform: rotateY(360deg) translateZ(-34px) translateY(-6.96px) rotateZ(180deg) rotateY(180deg) rotateX(45deg);
	}
  }
}