// ...
// Configuration
@import "../../Config";

.dice-page
{
  .columns
  {
	.column
	{
	  width: 100%;
	  @include mediaquery(sm)
	  {
		width: calc(33.333333% - .25em);

		.card
		{
		  height: 100%;
		}
	  }
	}
  }

  .log
  {
	margin-bottom: 0;
  }
  .team-dice
  {
	padding-bottom: 0;
  }

  #delete-all-rolls
  {
	@include default-button-style;
	background-color: get-color('error');
	margin-top: 1.5em;

	@include set-alternate-text-sizes;

	@include mediaquery(hover)
	{
	  background-color: get-color('error');
	}

	@include mediaquery(sm) {
	  position: absolute;
	  bottom: 2.25em;
	  right: 0;
	}
  }

  .roll-btn
  {
	margin-top: 1em;
  }
}